import * as React from "react";
import Hero from "../components/hero";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
// markup
const ContactPage = () => {
  return (
    <main>
      <title>Contact Us - VTAKU</title>
      <Hero />
      <ContactForm />
      <Footer />
    </main>
  );
};

export default ContactPage;
